.CamelCaseTraininglist{
  background: #F3F5F9;
  width:100%;
  display:block;
  padding-bottom: 40px;
  .classification{
    background: #FFFFFF;
    width:100%;
    display:block;
    margin-bottom:20px;
    padding-top:30px;
    .col-ul{
      display: block;
      width:100%;
      margin-bottom:30px;
      .span1{
        display: inline-block;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        position: relative;
        padding-right:20px;
        margin-right:14px;
        min-width:76px;
        text-align: right;
        &::after{
          position: absolute;
          right:1px;
          top:50%;
          height:15px;
          margin-top:-7.5px;
          background: #888888;
          width:1px;
          content:"";
        }
      }
      .nav-tab{
        display: inline-block;
        .ant-radio-button-wrapper{
          border:none;
          padding:0 10px;
          min-width:50px;
          height:30px;
          margin:0 10px;
          &.ant-radio-button-wrapper-checked{
            background: rgba(232, 238, 255, 0.62);
            border-radius: 3px;
          }
        }
        .ant-radio-button-wrapper:not(:first-child)::before{
          border:none;
          display: none;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          border:none;
          box-shadow: none;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
          border:none;
          box-shadow: none;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
          outline:none;
        }
      }
    }
    .col-bottom{
      display: flex;
      justify-content: space-between;
      padding:14px 0 18px;
      border-top:1px solid rgba(51,51,51,0.05);
      .left{
        padding-top:3px;
        span{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #888888;
        }
      }
      .right{
        .ant-input-affix-wrapper .ant-input{
          background: #F4F4F4;
          border-radius: 5px;
          border-color:#F4F4F4;
          padding-left:20px;
          min-width:400px;
        }
        .ant-input-suffix{
          .anticon{
            font-size:16px;
            color:#C9C9C9;
          }
        }
      }
    }
  }
  .CamelCaseplan{
    background: #F3F5F9;
    width:100%;
    min-height: calc(100vh - 550px);
    .col{
      display: flex;
      //align-items: center;
      background: #FFFFFF;
      border-radius: 10px;
      margin-bottom:20px;
      transition: 0.3s;
      -webkit-transition:0.3s; /* Safari */
      border: 1px solid #ffffff;
      .left{
        padding:30px;
        display: inline-block;
        cursor: pointer;
        >div{
          width:290px;
          height:220px;
          background-size: 100% 100% !important;
        }
        img{
          width:290px;
          height:220px;
          display: block;
        }
      }
      .right{
        padding:30px 50px 20px 0;
        flex: 1 1;
        .title-h3{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #333333;
          display: flex;
          align-items: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp:2;
          overflow: hidden;
          //display: grid;
          margin-bottom:15px;
          cursor: pointer;
          word-break: break-word;
          word-wrap: break-word;
          white-space: -o-pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: pre;
          white-space: pre-wrap;
          white-space: pre-line;
          img{
            width:4px;
            height:auto;
            margin-right:8px;
          }
        }
        .ul1{
          margin-bottom:12px;
          display: flex;
          align-items: center;
          min-height:23px;
          li{
            background: #F5F5F5;
            border-radius: 3px;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #666666;
            min-width:53px;
            height:23px;
            line-height: 23px;
            margin-right:10px;
            display: inline-block;
            word-break: break-word;
            word-wrap: break-word;
            white-space: -o-pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: pre;
            white-space: pre-wrap;
            white-space: pre-line;
          }
        }
        p{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #333333;
          height:48px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp:2;
          overflow: hidden;
          //display: grid;
          margin-bottom:12px;
          word-break: break-word;
          word-wrap: break-word;
          white-space: -o-pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: pre;
          white-space: pre-wrap;
          white-space: pre-line;
        }
        .ul2{
          display: flex;
          align-items: center;
          margin-bottom:32px;
          min-height:25px;
          li{
            margin-right:30px;
            display: flex;
            align-items: center;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            color: #333333;
            span{
              word-break: break-word;
              word-wrap: break-word;
              white-space: -o-pre-wrap;
              white-space: -moz-pre-wrap;
              white-space: pre;
              white-space: pre-wrap;
              white-space: pre-line;
            }
            i{
              width:22px;
              height:22px;
              margin-right:6px;
              border-radius: 50%;
              list-style: initial;
              font-style: initial;
              text-align: center;
              display: grid;
              align-items: center;
              img{
                height:10px;
                width:auto;
                display: block;
                margin:0 auto;
                text-align: center;
              }
              &.i1{
                background: #D8F1FF;
              }
              &.i2{
                background: #FFF5C3;
              }
              &.i3{
                background: #FFD7D7;
              }
              &.i4{
                background: #E2FFE3;
              }
              &.i5{
                background: #FFE9D7;
              }
              &.i6{
                background: #ECE2FF;
              }
            }
          }
        }
        .more{
          height:38px;
          padding:10px 20px;
          background: #F0F5FB;
          border-radius: 4px;
          width:100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span{
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #3A81F7;
            display: inline-block;
          }
          a{
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #3A81F7;
            display:flex;
            align-items: center;
            img{
              vertical-align: middle;
              width:19px;
            }
          }
        }
      }
      &:hover{
        border: 1px solid #0060FF;
        box-sizing: border-box;
      }
    }
  }
}


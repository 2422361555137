
.CamelCaseheader{
  background: #FFFFFF;
  width:100%;
  display: flow-root;
  align-items: center;
  justify-content: center;
  position: relative;
  height:104px;
  &::after{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    box-shadow: 0px 10px 15px rgba(231, 83, 1, 0.08);
    padding-bottom:10px;
    z-index:2;
    content:"";
  }
  :global{
    .head-h{
      width: 100%;
      height: 44px;
      background: #EDEEF0;
      margin: 0 auto;
    }
    .header_container{
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
    }
    .header_l{
      display: flex;
      justify-content: flex-start;
      line-height: 44px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #333333;
      p{
        margin: 0;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
        span{

        }
      }
      .ant-btn{
        background: #EC5245;
        border-radius: 20px;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        margin-top: 12px;
        margin-left: 30px;
        box-sizing: border-box;
        cursor: pointer;
        color: #fff;
        min-width: 87px;
        height: 21px;
        padding:0 10px;
        position: relative;
        padding-left: 29px;
        border:none;
        span{
          line-height: 21px;
          height:21px;
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #FFFFFF;
        }
        svg{
          position: absolute;
          left: 10px;
          top: 2px;
        }
      }
    }
    .header_r{
      line-height: 44px;
      user-select: none;
      display: flex;
      justify-content: space-between;
      .app-a{
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        margin-left: 10px;
        &:after{
          content: '';
          display: inline-block;
          width: 2px;
          height: 8px;
          border-radius: 10px;
          background: #333333;
          position: relative;
          margin: 0 16px;
          top: -1px;
        }
      }
      .right-span{
        line-height: 44px;
        user-select: none;
        display: inline-flex;
        float: right;
        i{
          list-style: none;
          background: #EAF3FF;
          border-radius: 2px;
          font-family: Alibaba PuHuiTi;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #3A81F7;
          min-width:36px;
          height:14px;
          padding:0 5px;
        }
        p{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          //line-height: 120.7%;
          color: #333333;
          display: block;
          margin-bottom:0;
          max-width: 73px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .login_btn{
          cursor: pointer;
          text-align: right;

        }
      }
      .right-text{
        float:right;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .left-span{
          margin-left:10px;
          margin-top: 10px;
          img{
            width:32px;
            height:32px;
            border:1px solid #333333;
            border-radius: 10px;
            margin-top: -14px;
          }
        }

      }

    }
    .Camel-content{
      //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    }
    .logo{
      flex: 1 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height:60px;
      img{
        height: 40px;
        //max-width: 240px;
        margin-right: 10px;
        //margin-top:12px;
      }
      h1{
        padding-left:12px;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        flex: 1 1;
        //border-left:2px solid #C4C4C4;
        position: relative;
        line-height: 60px;
        margin-bottom:0;
        &::after{
          position: absolute;
          left:0;
          top:50%;
          height:20px;
          margin-top:-10px;
          background:#C4C4C4;
          width:2px;
          content:"";
        }
      }
    }
    .nav-list{
      float:right;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;
      height:60px;

      .button-span{
        float:right;
        background: #E25151;
        border-radius: 28px;
        min-width:112px;
        line-height: 31px;
        height:31px;
        color:#ffffff;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        margin:0 24px;
      }
      ul{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom:0;
        .li{
          display: inline-block;
          &:last-child{
            a{
              padding-right:0;
            }
          }
          a{
            margin-left:40px;
            position: relative;
            display: block;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 120.7%;
            color: #333333;
            &::after{
              position: absolute;
              left:0;
              bottom:-20px;
              width:100%;
              height:4px;
              background: #EC5245;
              box-shadow: 0px 1px 3px rgba(247, 103, 58, 0.52);

              content:"";
              opacity: 0;
            }
          }
          &.active{
            a{
              font-weight: bold;
              color: #EC5245;
              font-size:16px;
              &::after{
                opacity: 1;
              }
            }
          }
          &:hover{
            a{
              font-weight: bold;
              color: #EC5245;
              font-size:16px;
              &::after{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.overlay-text{
  .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a{
    text-align: center;
  }
}
.menu_right_down_item{
  padding: 5px 12px;
  text-align: center;
}
